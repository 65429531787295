import { useFetcher, useLoaderData } from '@remix-run/react';
import { useEffect, useRef } from 'react';
import { useInterval } from 'react-use';
import { loader as rootLoader } from '~/root';

export default function ImpersonationBanner() {
  const { impersonation } = useLoaderData<typeof rootLoader>();
  const logOutFetcher = useFetcher();

  const expirationTimeRef = useRef<number | null>(null);
  const expirationTime = expirationTimeRef.current;

  useEffect(() => {
    if (impersonation && impersonation.expiresAt) {
      expirationTimeRef.current = new Date(impersonation.expiresAt).getTime();
    }
  }, [impersonation]);

  useInterval(
    () => {
      if (impersonation && expirationTime && expirationTime < Date.now()) {
        logOutFetcher.submit({}, { method: 'POST', action: '/?index' });
        expirationTimeRef.current = null;
      }
    },
    expirationTime && impersonation ? 1000 : null
  );

  if (!impersonation || !impersonation.token) {
    return null;
  }

  return (
    <>
      <div className="h-[36px] w-full" />
      <div className="bg-theme-yellow fixed left-0 right-0 top-0 z-50 flex w-full items-center justify-between px-4 py-2 text-sm font-bold shadow">
        <p>
          {impersonation.adminUser.displayName} impersonating:{' '}
          {impersonation.impersonatedUser.displayName}
        </p>
        <p>
          Session expires at:{' '}
          {new Date(impersonation.expiresAt).toLocaleString()}
        </p>
      </div>
    </>
  );
}
